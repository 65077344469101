import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Grid  from '@material-ui/core/Grid';
import { Link } from 'gatsby';
 
export default function Footer() {
    return (
        <footer className="site-footer">
	        <div className="spacer">
                
            </div>
            <div className="footerWrapper">
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{padding:'20px'}}>
                    <Grid item xs={12} md={10} >
                        <React.Fragment>
                        <h2>Funding Information</h2>
                        <div className='footerLogo'>
                            <a href='https://www.taubmaninstitute.org/' target='_blank'  rel="noopener noreferrer">
                                <StaticImage
                                    src={"../images/taubmannLogo.png"}
                                    alt="NIH logo"
                                    objectFit="fill"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                        <div className='footerLogo'>
                            <a href='https://www.copdfoundation.org/' target='_blank'  rel="noopener noreferrer">
                                <StaticImage
                                    src={"../images/copdfoundationLogo.png"}
                                    alt="copd foundation logo"
                                    objectFit="fill"
                                    placeholder="blurred"
                                />
                            </a>
                        </div>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <div>
			            <h2>Site Map</h2>
		                <div>
                            <ul className='footerListItem'>
                                <li>
                                    <Link to='/' className='footerLink'>About COPD</Link>
                                </li>
                                <li>
                                    <Link to='/about' className='footerLink'>Meet the Team</Link>
                                </li>
                                <li>
                                    <Link to='/resourceLinks' className='footerLink'>Resource Links</Link>
                                </li>
                                <li>
                                    <Link to='/recruitment' className='footerLink'>Recruitment</Link>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </Grid>
                    </Grid> 
            </div>
        </footer>
    )
}