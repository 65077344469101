import React from 'react'
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core'
export default function Navbar() {
    return (
        <nav>
          <div>
              <ul className='navigationBarNew'>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>  
                    <li>
                        <Link to='/' className='navBarLink'>Home</Link>
                    </li>
                </Grid>
                <Grid item xs={12} md={2}>
                    <li>
                        <Link to='/recruitment' className='navBarLink'>Join Study</Link>
                    </li>
                </Grid>
                <Grid item xs={12} md={4}>
                    <li>
                        <Link to='/forResearchers' className='navBarLink' style={{textAlign:'right'}}>For Physicians & Researchers</Link>
                    </li>
                </Grid>
                <Grid item xs={12} md={2}>
                    <li style={{textAlign:'center'}}>
                        <Link to='/about' className='navBarLink'>About Us</Link>
                    </li>
                </Grid>
                <Grid item xs={12} md={3}>
                    <li className='resourceLinkNavClass'>
                        <Link to='/resourceLinks' className='navBarLink'>Resource Links</Link>
                    </li>
                </Grid>
                </Grid>
              </ul>
          </div>
        </nav>
    )
}