import React from 'react'
import NavBar from './Navbar';
import '../styles/global.css';
import Grid from '@material-ui/core/Grid';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import CustomCarousel from './CustomCarousel';
import Button from '@material-ui/core/Button';

export default function Header(){
    return(
        <header>
        <Grid container spacing={3} className='applicationHeader' justifyContent="center" alignItems="center">
            <Grid item xs={2} align='right'>
              <Link to='/'>
                <StaticImage
                    src={"../images/COPDLogo.png"}
                    alt="Logo"
                    objectFit="fill"
                    placeholder="blurred"
                />
              </Link>
              </Grid>
              <Grid item xs={10}>  
              <Link to='/' className='websiteHeading'>
                <h1>MAP COPD &nbsp;
                  <Button variant="contained" size='large' onClick={()=>window.open('https://umhealthresearch.org/#studies/HUM00176147', "_blank")} style={{backgroundColor:'#B23114', color:'whitesmoke', marginBottom:'5px'}}>Learn More</Button>
                </h1> 
              </Link> 
              </Grid> 
            <Grid item xs={12}>
                <NavBar />
            </Grid>
        </Grid>
        <Grid container className='imageSideHeading'>
            <Grid item xs={12} md={5}>
                <CustomCarousel />
            </Grid>
            <Grid item xs={12} md={7}>
                <div className='missionStatement'>
                  <h1>Join the MAP COPD research study and help us discover why some smokers get lung disease at a young age while others don’t.</h1> 
                </div>
            </Grid>
        </Grid>
        </header>
    )
}