import React from 'react';
import '../styles/global.css';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Header from './Header';
import Helmet from 'react-helmet'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: '5%',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '05%',
    },
    align:'center',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '10px',
      padding: '40px',
      width: '95%',
      ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
        width: '95%',
      },
      height: '70%',
    },
  },
  customBorderRadius: {
    borderRadius: 25,
    zIndex: 2,
    padding:'10px',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      padding:'40px'
    }
  }
}));

export default function Layout({children}) {
    const classes = useStyles();
    const title = 'Map COPD';
    return (
        <div>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <Header />
            <div className={classes.root}>
                <Paper elevation={3} className={classes.customBorderRadius}>
                    {children}
                </Paper>
            </div>
            <Footer />
        </div>
    )
}