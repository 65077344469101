import React from 'react'
import '../styles/global.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function CustomCarousel(){
    const data = useStaticQuery(pageQuery);
    const settings = {
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false
    };

    return(
        <Slider {...settings}>
            {
                data.allFile.edges.map(({node}) => (
                  <div className='headerImage'>
                    <GatsbyImage 
                      style={{top:'8px'}}
                      image={node.childImageSharp.gatsbyImageData} 
                      alt={node.base} />
                  </div>
                ))
            }
        </Slider>
    )
}

const pageQuery = graphql`query newQuery {
    allFile(
      filter: {relativeDirectory: {eq: "headerImages"}}
      sort: {fields: base}
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(
                height: 360
                width: 700
                placeholder: BLURRED
                quality: 100
                transformOptions: {cropFocus: CENTER, fit: COVER}
                blurredOptions: {width: 100}
            )
          }
        }
      }
    }
  }`